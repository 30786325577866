#work {
  .content {
    animation: fadein 1s;
    gap: 0px;
    display: none;

    .subtitle {
      padding-top: 10px;
      padding-bottom: 40px;
    }

    .card {
      width: 300px;
      min-height: 200px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .logo {
      object-fit: cover;
      max-width: 400px;
      width: 100%;
      height: 200px;
    }
  }

  .show {
    display: flex;
  }

  .dot-switcher {
    gap: 20px;

    .dot {
      height: 24px;
      width: 24px;
      background: linear-gradient(135deg, rgba(242,242,242,1) 0%, rgba(212,212,212,1) 100%);
      border-radius: 50%;
      border-style: none;
    }

    .dot.highlighted {
      background: rgb(212,212,212);
      background: linear-gradient(135deg, rgba(212,212,212,1) 0%, rgba(122,122,122,1) 100%);
    }
  }
}

@media only screen and (max-width: 1180px) {
  #work {
    overflow-x: clip;
    
    .content {
      display: flex;
      margin-bottom: 40px;

      .items {
        flex-wrap: nowrap;
        
        .card {
          position: relative;
          left: 0;
          box-shadow: -5px 0 20px #aaa;
          transition: 0.4s ease-out;
        }

        .card:not(:first-child) {
          margin-left: -280px;
        }

        .card:hover ~ .card {
          left: 280px;
        }
      }
    }

    .dot-switcher {
      display: none;
    }
  }
}

@media only screen and (max-width: 560px) {
  #work {
    .content {
      .items {
        .card {
          width: 160px;
          min-height: 320px;
        }

        .card:not(:first-child) {
          margin-left: -180px;
        }
  
        .card:hover ~ .card {
          left: 180px;
        }
      }
    }
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
