body {
  margin: 0;
  font-family: "Reddit Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
}

html {
  scroll-snap-type: y mandatory;
  background: linear-gradient(180deg, rgba(255,255,255,1) 2%, rgba(190,234,255,1) 10%, rgba(255,255,255,1) 60%);
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

@media only screen and (min-width: 1180px) and (min-height: 860px) {
  section {
    scroll-snap-align: start;
  }
}

a {
  text-decoration: none;
  color: black;
}

.rounded {
  border-radius: 10px;
}

.card {
  padding: 20px;
  background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 40%);
}

.hbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.vbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.page {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 80px;
}
