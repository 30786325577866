.project {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0px;
  min-width: 460px;
  max-width: 920px;

  .card {
    height: 190px;
    min-width: 220px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow-y: scroll;
    background: linear-gradient(270deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 40%);

    .header {
      display: inline;
    }
    
    .vertical-separator {
      border-left: 1px solid darkgray;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .link {
      width: 18px;
      height: 18px;
      transform: translateY(2px);
    }
  }
  
  .image {
    height: 230px;
    width: 200px;
    min-width: 200px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(150,150,150,1) 100%);
  }

  .image#speed {
    object-fit: contain;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 180px;
  }

  .image#hitron {
    object-fit: contain;
  }

  .image#sorting {
    object-fit: fill;
  }
}

@media only screen and (max-width: 710px) {
  .project {
    flex-direction: column;
    min-width: 100%;

    .card {
      height: fit-content;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 0px;
      background: linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 40%);
    }
  
    .image {
      height: 230px;
      min-width: 260px;
      width: 100%;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .image#speed {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
