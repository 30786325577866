.navigation {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  background: linear-gradient(180deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%);
  z-index: 2;

  .hamburger {
    border-style: none;
    cursor: pointer;
    background: none;
    display: none;
    margin-left: auto;
    margin-right: 10px;

    .icon {
      height: 30px;
      width: 30px;
    }
  }

  .header {
    gap: 10px;
    padding-left: 10px;
    flex-wrap: nowrap;
    outline: none;
    box-shadow: none;
  
    .logo {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
    
    .name {
      font-size: 19px;
      font-weight: 800;
    }
  }
  
  .navigation-menu ul {
    display: flex;
    margin-right: 10px;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 14px;
  }
}

@media screen and (max-width: 600px) {
  .navigation {
    .hamburger {
      display: block;
    }

    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      background: linear-gradient(0deg, #ccc 0%, #fff 100%);
    }

    .navigation-menu li {
      text-align: center;
      margin: 0;
    }

    .navigation-menu li:hover {
      background-color: #eee;
    }

    .navigation-menu li a {
      width: 100%;
      height: 40px;
      display: block;
    }

    .navigation-menu li a p {
      margin: 0;
      transform: translateY(10px);
    }

    .navigation-menu.expanded ul {
      height: 100vh;
      transition: height 0.25s ease-in;
      border-top: 1px solid #ccc;
    }
  }
}
