#contact {
  .contacts {
    margin-bottom: 120px;
  }

  .contact {
    width: 100px;
  }
}

@media only screen and (max-width: 460px) {
  #contact {
    .contacts {
      flex-direction: column;
    }
  }
}
