#about {
  .content {
    gap: 0px;
    align-items: flex-start;
  }

  .name {
    font-size: 36px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .header {
    animation: fadeinout 4s;
    z-index: 1;
    padding-left: 0px;
    padding-bottom: 20px;
  }

  .profile {
    width: 100%;
    max-width: 360px;
  }
}

@keyframes fadeinout {
  0%   { opacity: 0; }
  25%  { opacity: 1; }
  75%  { opacity: 1; }
  100% { opacity: 0; }
}
